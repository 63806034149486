<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="Submit()"
      >
        {{ $t('saveData') }}
      </button>
      <b-row class="match-height">
        <b-col md="6">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('couponDetails') }}
                </h3>
              </div>
            </div>

            <div class="pl-2 pr-2 pt-1">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Coupon"
                  rules="required"
                >
                  <label for="Coupon">{{ $t('couponName') }} : <span class="text-danger">*</span></label>
                  <b-form-input
                    id="Coupon"
                    v-model="Obj.name"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('couponName')"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Detail"
                  rules="required"
                >
                  <label for="textarea">{{ $t('couponDetails') }} : <span class="text-danger">*</span></label>
                  <b-form-textarea
                    id="textarea"
                    v-model="Obj.description"
                    :placeholder="$t('couponDetails')"
                    :state="errors.length > 0 ? false:null"
                    rows="3"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="promotion">{{ $t('mnemonicPhrase') }} :</label>
                <b-form-input
                  id="promotion"
                  v-model="Obj.note"
                  type="text"
                  :placeholder="$t('quantity')"
                />
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Expire"
                  rules="required"
                >
                  <label for="expire">{{ $t('expiryDate') }} : <span class="text-danger">*</span></label>
                  <b-form-datepicker
                    id="expire"
                    v-model="Obj.expdate"
                    :state="errors.length > 0 ? false:null"
                    class="mb-1"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Expire"
                  rules="required"
                >
                  <label for="discount">{{ $t('discount') }} : <span class="text-danger">*</span></label>
                  <b-form-input
                    id="discount"
                    v-model="Obj.discount"
                    :state="errors.length > 0 ? false:null"
                    class="mb-1"
                    :placeholder="$t('quantity')"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="check">{{ $t('usageStatus') }}</label><br>
                <b-form-checkbox
                  v-model="Obj.active"
                  name="check-button"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                />
              </b-form-group>
            </div>

          </div>
        </b-col>

        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  {{ $t('user') }}
                </h3>

                <a
                  href="javascript:void(0)"
                  @click="modalGroup()"
                ><i class="fal fa-plus" /> {{ $t('key-75') }}</a>
              </div>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fieldsGroup"
                :items="itemsGroup"
                class="mb-2"
                show-empty
              >

                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>

                <template #cell(id)="data">
                  {{ `USR-${data.item.id}` }}
                </template>

              </b-table>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-modal
        ref="my-group"
        :title="$t('key-76')"
        size="lg"
        @ok="CalGroupUser()"
      >
        <div class="income-order-search order-search w-100">
          <div class="anan-input search-input">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="search"
                type="text"
                :placeholder="$t('search')"
                class="anan-input__input"
              >
              <div class="anan-input__suffix">
                <i class="anan-input__clear-btn anan-icon" />
                <i class="anan-input__suffix-icon anan-icon">
                  <i class="fal fa-search" />
                </i>
              </div>
            </div>
          </div>
        </div>

        <b-table
          responsive="sm"
          :fields="fieldsList"
          :items="paginatedItems"
          :selectable="selectable"
          :checked-rows.sync="selectedRows"
          show-empty
        >

          <template #empty>
            <div class="text-center">
              <img
                src="@/assets/images/anan-img/empty/empty.png"
                alt="empty"
                width="180px"
              >
              <p>No Data</p>
            </div>
          </template>

          <template #cell(checkbox)="data">
            <b-form-checkbox
              v-model="data.item.checked"
              @change="toggleChecked(data.item)"
            />
          </template>
          <template #cell(index)="data">
            USR-{{ currentPage * perPage - perPage + data.index + 1 }}
          </template>
          <!-- <template #cell(nameTrans)="data">
            {{ data.item.nameTrans }}
          </template> -->
        </b-table>

        <div class="demo-spacing-0 d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BTable,
  BFormCheckbox,
  BModal,
  BPagination,
  BFormGroup,
  BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BFormCheckbox,
    BModal,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormDatepicker,
    BFormTextarea,
  },
  data() {
    return {
      required,
      promotion: '',
      promotionRange: '',
      description: '',
      check: false,

      selectable: true,
      selectedRows: [],
      selectAll: false,

      // Table

      itemsGroup: [],

      fields: [
        {
          key: 'minimum', label: 'ขั้นตำ', sortable: false, thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'price', label: 'ราคา (เดิม)', sortable: false, thStyle: { width: '70%' }, thClass: 'text-center',
        },
        {
          key: 'action', label: 'จัดการ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-right',
        },
      ],
      items: [],

      fieldsTrans: [
        {
          key: 'nameShip', label: 'ชื่อ', sortable: false, thStyle: { width: '40%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'serviceFeeOld', label: 'ค่าบริการ(เดิม)', sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'ShipCostsOrigin', label: 'ค่าขนส่ง(เดิม)', sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'serviceFee', label: 'ค่าบริการ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'ShipCosts', label: 'ค่าขนส่ง', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'action', label: 'จัดการ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
      ],
      itemsTrans: [],

      fieldsTrans2: [
        { key: 'checkbox', label: '' },
        {
          key: 'index', label: 'หมายเลข', sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'nameTrans', label: 'ชื่อ', sortable: false, thStyle: { width: '85%' }, thClass: 'text-center',
        },
      ],
      itemsTrans2: [
        { nameTrans: 'รับสินค้าเอง' },
        { nameTrans: 'บริษัทจัดส่ง 690 บาท' },
        { nameTrans: 'PL ขนส่ง' },
        { nameTrans: 'ไปรษณีย์ (EMS)' },
        { nameTrans: 'นิ่มซี่เส็ง ภาคเหนือ' },
        { nameTrans: 'นิ่มเอ็กเพลส' },
        { nameTrans: 'Kerry' },
        { nameTrans: 'Flash Express' },
        { nameTrans: 'J&T Express' },
        { nameTrans: 'DHL' },
        { nameTrans: 'Best Express' },
        { nameTrans: 'ขนส่งเอกชนอื่นๆ (ระบุช่องหมายเหตุ)' },
        { nameTrans: 'ส่งจากไทยไปจีน' },
      ],
      itemsList: [],
      perPage: 7,
      currentPage: 2,
      subrole: [],
      module_list: [],
      module_use: '',
      group_user_list: [],
      group_use: '',
      group_user_type: 1,
      group_userloading: false,
      category: '',
      Obj: [{
        ID: null,
        name: null,
        description: null,
        note: null,
        expdate: null,
        discount: null,
        active: 0,
        groupuser: [{
          id: null,
          username: null,
          fullname: null,
        }],
      }],
    }
  },
  computed: {
    fieldsList() {
      return [
        { key: 'checkbox', label: '', thStyle: { width: '5%' } },
        {
          key: 'index', label: this.$t('number'), sortable: false, thStyle: { width: '20%' },
        },
        {
          key: 'username', label: this.$t('customerCode'), sortable: false, thStyle: { width: '20%' },
        },
        {
          key: 'fullname', label: this.$t('key-77'), sortable: false, thStyle: { width: '55%' },
        },
      ]
    },

    fieldsGroup() {
      return [
        {
          key: 'id', label: this.$t('number'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customerCode'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'fullname', label: this.$t('key-77'), sortable: false, thStyle: { width: '55%' }, thClass: 'text-center', tdClass: 'text-right',
        },
      ]
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.perPage
      const endIndex = startIndex + this.perPage
      return this.itemsList.slice(startIndex, endIndex)
    },
  },
  mounted() {
    this.sub_role = []
    // this.getData('all')
    this.getUserList()
  },
  methods: {
    async getData(type) {
      this.isActive = type
      try {
        const { data: res } = await this.$http.get('/modulelist/index')
        this.itemsList = res
      } catch (e) {
        console.log(e)
      }
    },
    async getUserList() {
      try {
        const { data: res } = await this.$http.get('/users/list')
        this.itemsList = res
      } catch (e) {
        console.log(e)
      }
    },
    modalTrans() {
      this.$refs['my-trans'].show()
    },
    modalGroup() {
      this.$refs['my-group'].show()
    },
    addRow() {
      const newRow = {
        minimum: 0,
        price: 0,
      }
      this.items.push(newRow)
    },
    removeRow(index) {
      this.items.splice(index, 1)
    },
    addRowTrans() {
      const newRow = {
        nameShip: '',
        serviceFeeOld: 0,
        ShipCostsOrigin: 0,
        serviceFee: 0,
        ShipCosts: 0,
      }
      this.itemsTrans.push(newRow)
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const grouparr = []
          this.itemsGroup.forEach(item => {
            const Obj = {
              id: item.id,
              username: item.username,
              fullname: item.fullname,
            }
            grouparr.push(Obj)
          })
          const Objnew = {
            name: this.Obj.name,
            description: this.Obj.description,
            note: this.Obj.note,
            expdate: this.Obj.expdate,
            discount: this.Obj.discount,
            active: this.Obj.active,
            groupuser: grouparr,
          }
          console.log(Objnew)
          this.$http
            .post('/coupon/coupon_update', Objnew)
            .then(() => {
              this.Success(this.$t('key-308'))
              this.$router.push({ name: 'admin-coupon' })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    CalModule() {
      const moduleArr = this.module_use.sub_role.split(',')
      this.subrole.forEach(item => {
        if (moduleArr.includes(item.level.toString())) {
          this.$set(item, 'status', true)
        } else {
          this.$set(item, 'status', false)
        }
      })
    },
    CalGroupUser() {
      const Checked = this.itemsList.filter(item => item.checked)
      this.itemsGroup = Checked
      // this.itemsGroup.forEach(item => {

      // })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
